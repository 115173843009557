@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-app {
    background: hsl(0deg 0% 10%);
}
.bg-chat {
    background: hsl(0deg 0% 20%);
}

.primary {
    fill: #A5B3BB;
}

.secondary {
    fill: #0D2B3E;
}
.icon-close .secondary, .icon-attach .secondary {
    fill: rgb(229, 231, 235);
}
